import { useCookies } from "react-cookie"
import CookiesNotice from "../components/cookiesNotice"
import Footer from "../layout/footer"
import Header from "../layout/header"
import { useEffect, useState } from "react"
import axios from "axios"
import { baseAPI } from "../utils/urls"
import { doNothing } from "../utils/utilFunctions"

const Account = () => {
    const [userDetails, setUserDetails] = useCookies(['email'])
    const [userStatus, setUserStatus] = useState<any>("")
    const [token, setToken] = useCookies<string>(['user_token'])
    const [cancelError, setCancelError] = useState('')
    const [cancelSuccess, setCancelSuccess] = useState('')
    const [showCancel, setShowCancel] = useState(false)


    const getUserStatus = async () => {
        const response = await axios.post(baseAPI+'verify-customer/', {
            email: userDetails.email,
          });
        setUserStatus(response.data)
    }

    useEffect(()=>{
        getUserStatus()
    }, [])

    useEffect(()=>{
        if (!token.user_token){
            window.location.href='/login'
        } 
    })

    const startCancel = () => {
        if (showCancel === false){
            setShowCancel(true)
        } else {
            setShowCancel(false)
        }
    }

    const cancelSubscription = async () => {
        setShowCancel(false)
        try {
            const response = await axios.post(baseAPI+'cancel-subscription/', {
              email: userDetails.email,
              plan: (userStatus.user_plan === 'premium' ? 'PLN_vzchae54ou89hfw': 'PLN_9ywgrzywt3jkman'),
            });
      
            setCancelSuccess("Your plan has been canceled")
          } catch (error) {
            setCancelError(`You subscription is already inactive. You will remain on the ${userStatus.user_plan} plan until it expires.`)
          }
    }

    return (
        <>
        <Header />
        <div className="main-body">
            <div className="">
            <div className="title-area">
        <h2 className="page-title">Account Settings</h2>
        </div>
        </div>
        <br />
        <div className="account-box">
        <div className="p-4 border rounded-lg">
        <h3 className="text-3xl">Account Details</h3>
        <br />
        <label htmlFor="">
        <p>Email</p>
        <br />
        <input type="text" className="input-field" value={userDetails.email} />
        </label>
        <br />
        <br />
        <label htmlFor="">
           <p>Password</p>
           <br />
            <button className="px-5 py-2 bg-purple-700 text-white rounded-lg">Change Password</button>
        </label>
        </div>
        <br />
          <div className="p-4 border rounded-lg">
          <h3 className="text-3xl">Subscription</h3>
            <br />
            {
                userStatus && userStatus.user_plan != "free" ? 
                <div>
                    <h4>Your current plan:</h4>
                    <h3 className="text-xl">{userStatus.user_plan}</h3>
                    <br />
                    {
                        cancelError ? 
                        <div>
                            <p className="text-red-600">{cancelError}</p>
                            <br />
                        </div>
                        :
                        doNothing()
                    }
                    {
                        showCancel ?
                        <div className="p-3 border rounded-lg">
                            <p>Are you sure you want to cancel your {userStatus.user_plan} plan?</p>
                            <br />
                            <div className="flex gap-5">
                            <button className="px-5 py-2 bg-purple-700 text-white rounded-lg" onClick={cancelSubscription}>Yes</button>
                            <button className="px-5 py-2 bg-purple-700 text-white rounded-lg" onClick={startCancel}>No</button>
                            </div>
                        </div>
                        :
                        <button className="px-5 py-2 bg-purple-700 text-white rounded-lg" onClick={startCancel}>Cancel Plan</button>
                    }
                </div>
                :
                <div>
                    <h3>You are not currently subscribed to any plan.</h3>
                    <br />
                    <a className="px-5 py-2 bg-purple-700 text-white rounded-lg" href="/pricing">Check out Plans</a>
                </div>                
            }
          </div>
          <br />
          <div className="p-4 border rounded-lg">
          <h3 className="text-3xl">Other Actions</h3>
            <br />
            <div className="bg-yellow-100 p-3 rounded-lg w-fit">
                <p>Careful, this action cannot be undone</p>
                <br />
                <a className="px-5 py-2 bg-red-700 text-white rounded-lg" href="/pricing">Delete Account</a>
            </div>
          </div>
        </div>
        </div>
        <CookiesNotice />
        <Footer />
        </>
    )
}

export default Account