import axios from "axios"
import CookiesNotice from "../components/cookiesNotice"
import Footer from "../layout/footer"
import Header from "../layout/header"
import { baseAPI } from "../utils/urls"
import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { doNothing } from "../utils/utilFunctions"

const PlanConfirmation = () => {
    const [userDetails, setUserDetails] = useCookies(['email'])
    const [userStatus, setUserStatus] = useState<any>("")

    const getUserStatus = async () => {
        const response = await axios.post(baseAPI+'verify-customer/', {
            email: userDetails.email,
          });
        setUserStatus(response.data)
    }

    useEffect(()=>{
        getUserStatus()
    }, [])


    return (
        <>
        <Header />
        <div className="main-body p-3">
            <div className="my-auto mx-auto bg-white p-12 mt-24 rounded-xl shadow-lg">
            {
                userStatus.user_plan === "premium" ? 
                <div>
                <h2 className="text-center text-4xl">Awesome! You're on the <span className="text-purple-800">Premium</span> plan.</h2>
                <br />
                <div className="text-xl">
                <h4>You've got:</h4>
                <p>1. Access to all basic free icons</p>
                <br />
                    <p>2. Access to premium icons:</p>
                    <p>- Exclusive and highly detailed icons</p>
                    <p>- Industry-specific icons</p>
                    <br />
                    <p>3.Basic customization options</p>
                     <p>- Standard sizes</p>
                     <p>- Standard stroke thickness</p>
                    <br />
                    <p>4. Advanced customization options:</p>
                     <p>- Customisable colours</p>
                </div>
                <br />
                </div>
                : userStatus.user_plan === 'pro' ?
                <div>
                <h2 className="text-center text-4xl">Awesome! You're on the <span className="text-yellow-800">Premium</span> plan.</h2>
                <br />
                <div className="text-xl">
                <h4>You've got:</h4>
                <p>1. Access to all basic free icons</p>
                <br />
                <p>2. Access to premium icons:</p>
                <br />
                <p>- Exclusive and highly detailed icons</p>
                <br />
                <p>- Industry-specific icons</p>
                <br />
                <p>3. Access to pro icons:</p>
                    <br />
                    <p>-Animated Icons</p>
                    <br />
                    <p>4. Basic customisation options</p>
                    <br />
                     <p>- Standard sizes</p>
                     <br />
                     <p>- Standard stroke thickness</p>
                     <br />
                    <p>5. Advanced customization options:</p>
                    <br />
                     <p>- Custom icon sizes</p>
                     <br />
                     <p>- Adjustable stroke thickness</p>
                     <br />
                     <p>- Customisable colours</p>
                </div>
                <br />
                </div>
                :
                doNothing()

            }
                <br />
                <a className="bg-purple-900 py-3 px-5 text-white rounded-lg" href="/icon">Browse Icons</a>
            </div>
        </div>
        <CookiesNotice/>
        <Footer />
        </>
    )
}

export default PlanConfirmation