import { Dispatch, useEffect, useState } from "react"
import Header from "../layout/header";
import Footer from "../layout/footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseAPI } from "../utils/urls";
import { useCookies } from "react-cookie"
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga4'
import CookiesNotice from "../components/cookiesNotice";
import { sendGAEvent } from "../utils/utilFunctions";




interface Props {
    item: {
        name: string;
        amount: number;
        plan: string;
    }
}

const Payment:React.FC<Props> = ({item}) => {
    ReactGA.send({
        hitType: 'pageview',
        page:"/payment",
        title:"Payment",
    })

    const [token, setToken] = useCookies(['user_token'])
    const [userDetails, setUserDetails] = useCookies(['email'])
    const [paymentError, setPaymentError] = useState<string | undefined>()
    const navigate = useNavigate()
    const [userStatus, setUserStatus] = useState<any>("")

    const getUserStatus = async () => {
        const response = await axios.post(baseAPI+'verify-customer/', {
            email: userDetails.email,
          });
        setUserStatus(response.data)
    }

    useEffect(()=>{
        getUserStatus()
    }, [])

    useEffect(()=>{
        if(userStatus.user_plan === "premium" || userStatus.user_plan === "pro"){
            navigate('/plan-confirmation')
        }
        if(!item){
            navigate('/pricing')
        }
        window.scrollTo(0, 0);
    }, [])
    

    const handlePaystackPayment = async () => {
        sendGAEvent('Button Click', 'payment_attempt', 'payment_attempt');
        try {
          const response = await axios.post(baseAPI+'initiate-payment/', {
            email: userDetails.email,
            amount: item.amount,
            plan: item.plan,
          });
    
          const { authorization_url } = response.data.data;
    
          // Redirect the user to the Paystack payment page
          window.location.href = authorization_url;
        } catch (error) {
          setPaymentError('There was a problem in your payment, please try again.')
        }
      };

return(
    <>
    <Header />
    <title>{item.name} Plan | DeviceIcons</title>
    <div className="main-body">
    <div className="title-area">
        <div className="page-title">
            Go {item.name}
        </div>
    </div>
    <br />
    <div className="payment-area">
        <div className="basis-1/2 pa-1 p-4">
        {
            item.name === 'Premium'?
            <div>
                <p className="text-4xl text-purple-800">Premium Plan</p>
                <br />
                <p className="text-lg">Get access to premium icons and more customisation options!</p>
                <br />
                <p>The <b>Premium</b> plan includes:</p>
                <br />
                <p>1. Access to all basic free icons</p>
                <br />
                    <p>2. Access to premium icons:</p>
                    <br />
                    <p>- Exclusive and highly detailed icons</p>
                    <br />
                    <p>- Industry-specific icons</p>
                    <br />
                    <p>3.Basic customization options</p>
                    <br />
                     <p>- Standard sizes</p>
                     <br />
                     <p>- Standard stroke thickness</p>
                    <p>4. Advanced customization options:</p>
                    <br />
                     <p>- Customisable colours</p>

            </div>
            : <div>
            <p className="text-4xl text-yellow-800">Pro Plan</p>
            <br />
            <p className="text-lg">Everything DeviceIcons! Get access to pro icons and custom customisation options!</p>
            <br />
            <p>The <b>Pro</b> plan includes:</p>
            <br />
            <p>1. Access to all basic free icons</p>
            <br />
                <p>2. Access to premium icons:</p>
                <br />
                <p>- Exclusive and highly detailed icons</p>
                <br />
                <p>- Industry-specific icons</p>
                <br />
                <p>3. Access to pro icons:</p>
                    <br />
                    <p>-Animated Icons</p>
                    <br />
                    <p>4. Basic customisation options</p>
                    <br />
                     <p>- Standard sizes</p>
                     <br />
                     <p>- Standard stroke thickness</p>
                     <br />
                    <p>5. Advanced customization options:</p>
                    <br />
                     <p>- Custom icon sizes</p>
                     <br />
                     <p>- Adjustable stroke thickness</p>
                     <br />
                     <p>- Customisable colours</p>

        </div>
        }
        

        </div>
        <div className="pa-2 basis-1/2">
        <div className="payment-form">
            <p className="text-xl"><b>Payment</b></p>
            <br />
            <hr />
            <br />
            <p>Items your'e paying for:</p>
            <br />
            {
                item.name === 'Premium' ? <> <div className="grid grid-cols-2 gap-20">
                <p className="text-xl">Premium Plan</p>
                <p className="text-xl">₦60,000 (${item.amount})</p>
                </div>
                <br />
                <hr />
                <br />
                <div className="grid grid-cols-2 gap-20">
                <p className="text-lg">Subtotal</p>
                <p className="text-lg">₦60,000 (${item.amount})</p>
                </div>
                </>
                :
                <>
                <div className="grid grid-cols-2 gap-20">
            <p className="text-xl">Pro Plan</p>
            <p className="text-xl">₦80,000 (${item.amount})</p>
            </div>
            <br />
            <hr />
            <br />
            <div className="grid grid-cols-2 gap-20">
            <p className="text-lg">Subtotal</p>
            <p className="text-lg">₦80,000 (${item.amount})</p>
            </div>
            </>
            }
            <br />
            <p>Choose a payment method:</p>
            <br />
            {
             paymentError && <><div className="bg-red-600 text-white p-2 rounded">{paymentError}</div><br /></>
            }
            {
                token.user_token ? <button className="text-lg border bg-white shadow-md p-3 rounded-xl" onClick={handlePaystackPayment}> Pay with Card</button> : <div><p className="text-red-600">You are not Logged In.</p> <a href="/login" className="underline font-bold">Login</a> to be able to make payment</div>

            }

        </div>
         </div>     
    </div>
    </div>
    <CookiesNotice />
    <Footer />
    </>
)
}

export default Payment