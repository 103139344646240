import { Dispatch, useContext, useEffect, useState } from "react"
import Footer from "../layout/footer"
import Header from "../layout/header"
import { useNavigate } from "react-router-dom"
import { shareImage } from "../utils/images"
import axios from "axios"
import { baseAPI } from "../utils/urls"
import { Helmet } from "react-helmet-async"
import ReactGA from 'react-ga4'
import CookiesNotice from "../components/cookiesNotice"
import { sendGAEvent } from "../utils/utilFunctions"


interface Props{
    setItem: Dispatch<object>
}

const Pricing: React.FC<Props> = ({setItem}) => {
      ReactGA.send({
        hitType: 'pageview',
        page:"/pricing",
        title:"Pricing",
    })


  const schemaData = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "DeviceIcons",
        "url": "https://www.deviceicons.com/pricing",
        "description": "Choose the perfect plan for your needs. Explore our free and premium plans, offering a range of icons and customization options.",
        "itemListElement": [
          {
            "@type": "Offer",
            "name": "Free Plan",
            "description": "Includes basic icons with limited customisation.",
            "url": "https://www.deviceicons.com/pricing",
            "priceCurrency": "NGN",
            "price": "0.00",
            "eligibleQuantity": {
              "@type": "QuantitativeValue",
              "value": 1
            },
            "availability": "https://schema.org/InStock"
          },
        {
          "@type": "Offer",
          "name": "Premium Plan",
          "description": "Offers premium and industry-specific icons and more customisation options, including different sizes, thickness and colours.",
          "url": "https://www.deviceicons.com/pricing",
          "priceCurrency": "NGN",
          "price": "60000.00",
          "eligibleQuantity": {
            "@type": "QuantitativeValue",
            "value": 1
          },
          "availability": "https://schema.org/InStock"
        },
        {
          "@type": "Offer",
          "name": "Pro Plan",
          "description": "Offers all premium features along with full customisation and animated icons.",
          "url": "https://www.deviceicons.com/pricing",
          "priceCurrency": "NGN",
          "price": "80000.00",
          "eligibleQuantity": {
            "@type": "QuantitativeValue",
            "value": 1
          },
          "availability": "https://schema.org/InStock"
        }
      ]
        
    };

    const [exchangeRate, setExchangeRate] = useState<any>('');
    const navigate = useNavigate()

    useEffect(() => {
        const fetchExchangeRate = async () => {
            const response = await axios.get(baseAPI+'get-exchange-rate/');
            setExchangeRate(response.data.conversion_rates.NGN);
        };
        fetchExchangeRate();
      }, []);


    return(
        <>
        <Helmet>
        <title>Pricing | DeviceIcons</title>
        <meta name="description" content="Choose the perfect plan for your needs. Explore our free and premium plans, offering a range of icons and customization options."/>
        <meta name="keywords" content="device icons pricing, free icon plan, premium icon plan, icon subscription, customizable icons"/>
        <meta name="author" content="DeviceIcons"/>
        <meta property="og:title" content="Pricing | DeviceIcons"/>
        <meta property="og:description" content="Choose the perfect plan for your needs. Explore our free and premium plans, offering a range of icons and customization options."/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://deviceicons.com/pricing"/>
        <meta property="og:image" content={shareImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Pricing | DeviceIcons"/>
        <meta name="twitter:description" content="Choose the perfect plan for your needs. Explore our free and premium plans, offering a range of icons and customization options."/>
        <meta name="twitter:image" content={shareImage}/>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
        </Helmet>
        <Header />
        <div className="main-body">
            <div className="title-area">
            <div className="page-title">Pricing</div>
            </div>
            <br />
            <h3 className="text-3xl text-center">Flexible Plans Suited to Your Iconic Needs</h3>
            <br />
            <p className="text-center pricing-intro"> Whether you're looking for a basic collection of free icons or advanced customization options <br /> with premium access, we have a plan that's perfect for you.</p>
            <br />
            <div className="plans-area">
                <div className="plan">
                    <h4 className="text-3xl pb-4">Free</h4>
                    <br />
                    <h4 className="text-4xl">₦0</h4>
                    <h4 className="text-2xl">($0)</h4>
                    <span>/year</span>
                    <br />
                    <br />
                    <button className="plan-button" onClick={()=>{sendGAEvent('Button Click', 'select_free_plan', 'select_free_plan'); navigate('/icons')}}>
                       Start Free
                    </button>
                    <br />
                    <br />
                    <br />
                    <div className="text-sm">
                    <p><b>Access to all basic free icons</b></p>
                    <br />
                    <hr />
                    <br />
                    <p><b>Basic customization options</b></p>
                    <br />
                     <p>Standard sizes</p>
                     <br />
                     <p>Standard stroke thickness</p>
                     <br />
                     <hr />
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>

                    </div>
                </div>
                <div className="plan">
                    <h4 className="text-3xl border-b-4 w-3/5 border-purple-600 pb-2 mx-auto">Premium</h4>
                    <br />
                    <h4 className="text-4xl">₦60,000</h4>
                    <h4 className="text-2xl">(${exchangeRate && Math.floor(60000/exchangeRate)})</h4>
                    <span>/year</span>
                    <br />
                    <br />
                    <button className="plan-button" 
                    onClick={()=>{sendGAEvent('Button Click', 'select_premium_plan', 'select_premium_plan'); setItem({name: 'Premium', amount: Math.floor(60000/exchangeRate), plan: 'PLN_vzchae54ou89hfw'}); exchangeRate && navigate('/payment')}}
                    >
                       Go Premium
                    </button>
                    <br />
                    <br />
                    <br />
                    <div className="text-sm">
                    <p><b>Access to all basic free icons</b></p>
                    <br />
                    <hr />
                    <br />
                    <p><b>Access to premium icons:</b></p>
                    <br />
                    <p>Exclusive and highly detailed icons</p>
                    <br />
                    <p>Industry-specific icons</p>
                    <br />
                    <hr />
                    <br />
                    <p><b>Basic customization options</b></p>
                    <br />
                     <p>Standard sizes</p>
                     <br />
                     <p>Standard stroke thickness</p>
                     <br />
                     <hr />
                     <br />
                     <p><b>Advanced customization options:</b></p>
                    <br />
                     <p>Customisable colours</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                     <br />
                     <p>-</p>
                    </div>
                </div>
                <div className="plan">
                    <h4 className="text-3xl border-b-4 border-yellow-600 pb-2 w-3/5 mx-auto">Pro</h4>
                    <br />
                    <h4 className="text-4xl">₦80,000</h4>
                    <h4 className="text-2xl">(${exchangeRate && Math.floor(80000/exchangeRate)})</h4>
                    <span>/year</span>
                    <br />
                    <br />
                    <button className="plan-button" 
                    onClick={()=>{sendGAEvent('Button Click', 'select_pro_plan', 'select_pro_plan'); setItem({name: 'Pro', amount: Math.floor(80000/exchangeRate), plan: 'PLN_9ywgrzywt3jkman'}); exchangeRate && navigate('/payment')}}
                    >
                       Go Pro
                    </button>
                    <br />
                    <br />
                    <br />
                    <div className="text-sm">
                    <p><b>Access to all basic free icons</b></p>
                    <br />
                    <hr />
                    <br />
                    <p><b>Access to premium icons:</b></p>
                    <br />
                    <p>Exclusive and highly detailed icons</p>
                    <br />
                    <p>Industry-specific icons</p>
                    <br />
                    <hr />
                    <br />
                    <p><b>Access to pro icons:</b></p>
                    <br />
                    <p>Animated Icons</p>
                    <br />
                    <hr />
                    <br />
                    <p><b>Basic customisation options</b></p>
                    <br />
                     <p>Standard sizes</p>
                     <br />
                     <p>Standard stroke thickness</p>
                     <br />
                     <hr />
                     <br />
                    <p><b>Advanced customization options:</b></p>
                    <br />
                     <p>Customisable colours</p>
                     <br />
                     <p>Custom icon sizes</p>
                     <br />
                     <p>Adjustable stroke thickness</p>
                    </div>
                </div>
            </div>
            </div>
            <CookiesNotice />
            <Footer />  
        </>
    )
}

export default Pricing