import { Helmet } from "react-helmet-async"
import Footer from "../layout/footer"
import Header from "../layout/header"
import { shareImage } from "../utils/images"
import ReactGA from 'react-ga4'
import CookiesNotice from "../components/cookiesNotice"


const Docs: React.FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page:"/docs",
        title:"Docs",
    })

    
    return(
        <>
        <Helmet>
        <title>Docs | DeviceIcons</title>
        <meta name="description" content="Find detailed documentation for DeviceIcons. Learn how to use our icon pack and integrate it into your projects."/>
        <meta name="keywords" content="device icons documentation, icon usage guide, icon integration, SVG icons, developer guide"/>
        <meta name="author" content="DeviceIcons"/>
        <meta property="og:title" content="Docs | DeviceIcons"/>
        <meta property="og:description" content="Find detailed documentation for DeviceIcons. Learn how to use our icon pack and integrate it into your projects."/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://deviceicons.com/docs"/>
        <meta property="og:image" content={shareImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Docs | DeviceIcons"/>
        <meta name="twitter:description" content="Find detailed documentation for DeviceIcons. Learn how to use our icon pack and integrate it into your projects."/>
        <meta name="twitter:image" content={shareImage}/>
        <script type="application/ld+json">
         </script>
        </Helmet>
        <Header />
        

        <div className="main-body">
        <div className="title-area">
        <h2 className="page-title">Docs</h2>
        </div>
        <div className="docs">
            <br />
        <div className="docs-inner">
            <div className="docs-1 sideNav">
            <ul>
        <li><a href="#introduction">1. Introduction</a></li>
        <li>
            <a href="#getting-started">2. Getting Started</a>
            <ul>
                <li>- <a href="#navigation">Navigation</a></li>
                <li>- <a href="#searching-for-icons">Searching for Icons</a></li>
                <li>- <a href="#selecting-and-customizing-icons">Selecting and Customizing Icons</a></li>
            </ul>
        </li>
        <li><a href="#icon-specifications">3. Icon Specifications</a></li>
        <li>
            <a href="#downloading-icons">4. Downloading Icons</a>
            <ul>
                <li>- <a href="#steps-to-download">Steps to Download</a></li>
                <li>- <a href="#customization-options">Customization Options</a></li>
            </ul>
        </li>
        <li>
            <a href="#integration">5. Integration</a>
            <ul>
                <li>- <a href="#web-integration">Web Integration</a></li>
                <li>- <a href="#mobile-and-desktop-integration">Mobile and Desktop Integration</a></li>
            </ul>
        </li>
        <li>
            <a href="#licensing">6. Licensing</a>
            <ul>
                <li>- <a href="#free-plan-license">Free Plan License</a></li>
                <li>- <a href="#premium-plan-license">Premium Plan License</a></li>
                <li>- <a href="#pro-plan-license">Pro Plan License</a></li>
            </ul>
        </li>
        <li><a href="#faqs">7. FAQs</a></li>
        <li><a href="#contact-us">8. Contact Us</a></li>
    </ul>

                

            
            </div>
            <div className="docs-2">
            <h4 id="introduction">Introduction</h4>
    <p>
        <strong>DeviceIcons</strong> offers a diverse collection of high-quality icons representing various devices such as phones, laptops, desktops, tablets, game consoles and more. Whether you're designing a website, mobile app, or presentation, our icons are crafted to enhance your visual communication and user experience.
    </p>

    <h4 id="getting-started">Getting Started</h4>
    <h5 id="navigation">Navigation</h5>
    <p>
        Use the search bar or browse categories to find icons that match your requirements.
    </p>

    <h5 id="searching-for-icons">Searching for Icons</h5>
    <p>
        Enter keywords in the search bar to find specific icons.
        <br/>
        Search for device types e.g. phones or icon scenarious, e.g. charging.
    </p>

    <h5 id="selecting-and-customizing-icons">Selecting and Customizing Icons</h5>
    <p>
        Click on an icon to view details.
        <br/>
        Customize size and thickness using the options provided.
        <br/>
        Preview changes before downloading.
    </p>

    <h4 id="icon-specifications">Icon Specifications</h4>
    <p>
        Our icons are primarily available in SVG format for scalability and flexibility. PNG formats may also be available for specific use cases where transparency is required.
    </p>

    <h4 id="downloading-icons">Downloading Icons</h4>
    <h5 id="steps-to-download">Steps to Download</h5>
    <p>
        Select an icon and customize size and thickness if desired.
        <br/>
        Click the download button to save the icon to your device.
    </p>

    <h5 id="customization-options">Customization Options</h5>
    <p>
        Adjust icon size to fit your design needs.
        <br/>
        Modify stroke thickness to achieve desired visual impact.
    </p>

    <h4 id="integration">Integration</h4>
    <h5 id="web-integration">Web Integration</h5>
    <p>
        Copy the SVG code directly into your HTML file.
        <br/>
        Use CSS to style and manipulate icon appearance.
    </p>

    <h5 id="mobile-and-desktop-integration">Mobile and Desktop Integration</h5>
    <p>
        Incorporate icons into your app design using appropriate frameworks and libraries.
        <br/>
        Ensure compatibility with different screen resolutions and platforms.
    </p>

    <h4 id="licensing">Licensing</h4>
    <p>
        <strong>DeviceIcons</strong> offers different licensing terms based on the subscription plan you choose. Please review the details below to understand your rights and obligations when using our icons.
    </p>

    <h5 id="free-plan-license">Free Plan License</h5>
    <p>
        - Access to all basic free icons.  <br />
        - Personal and commercial use allowed. <br />
        - Attribution required: You must credit "DeviceIcons" somewhere in your project. <br />
        - Redistribution or reselling of icons is not permitted. <br />
    </p>

    <h5 id="premium-plan-license">Premium Plan License</h5>
    <p>
        - Access to premium icons. <br />
        - Personal and commercial use allowed. <br />
        - No attribution required. <br />
        - Redistribution or reselling of icons is not permitted. <br />
    </p>

    <h5 id="pro-plan-license">Pro Plan License</h5>
    <p>
        - Access to premium icons and advanced customization options. <br />
        - Personal and commercial use allowed. <br />
        - No attribution required. <br />
        - Redistribution or reselling of icons is not permitted. <br />
    </p>
    <p>You can find the Licence Agreement <a href="/licence" className="font-bold">here</a>.</p>

    <h4 id="faqs">FAQs</h4>
    <p>
        Explore our FAQ section for answers to commonly asked questions about using <strong>DeviceIcons</strong> effectively.
    </p>

    <h4 id="contact-us">Contact Us</h4>
    <p>
        For further assistance or inquiries, please contact our support team at support@deviceicons.com
    </p>


            </div>
        </div>

        </div>
        </div>
        <CookiesNotice />
        <Footer />
        </>
    )
}

export default Docs