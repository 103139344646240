import { Helmet } from "react-helmet-async"
import Header from "../layout/header"
import Footer from "../layout/footer"

const PageNotFound = () => {
    return (
        <>
        <Helmet>
            <title>Page Not Found | DeviceIcons</title>
        </Helmet>
        <Header />
        <div className="main-body">
            <div className="my-auto mx-auto text-center">
                <h1 className="text-6xl text-purple-800">Not Found</h1>
                <br />
                <p className="text-3xl">Sorry, but we don't have the page you're looking for. </p>
                <p>(Maybe check the URL again?)</p>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default PageNotFound