import { Cookies, useCookies } from "react-cookie"
import cookies_icon from '../assets/media/cookies.svg'

const CookiesNotice: React.FC = () => {
    const [cookeAccept, setCookieAccept] = useCookies(['accepted'])

        const acceptCookie = () => {
            setCookieAccept('accepted', true, { path: '/', maxAge: 604800 })
        }

    return(
        <>
        {
            cookeAccept.accepted != true ? <div className="fixed p-4 bottom-0 bg-purple-700 w-3/4 text-white m-8 rounded-xl shadow">
            <p>
            <div className="flex">
            <img src={cookies_icon} className="heading-icon mr-2" alt="" /> <h4 className="text-2xl mb-2"> Yum, Cookies!</h4>
            </div>
            We use cookies to ensure you get the best experience on our website. Some cookies are essential for site functionality. By continuing to use our site, you accept our use of these essential cookies.
            </p>
            <div className="button-flex">
            <button className="mt-2 px-4 py-2 rounded-lg bg-white text-purple-700 text-center" onClick={acceptCookie}>Accept Cookies</button>             
            <a className="mt-2 px-4 py-2 rounded-lg border-2 text-white text-center" href="/privacy-policy#cookies">Cookie Policy</a>
            </div>
        </div> : null
        }
        </>
    )
}

export default CookiesNotice