import { useParams } from "react-router-dom"
import Header from "../layout/header"
import CookiesNotice from "../components/cookiesNotice"
import Footer from "../layout/footer"
import { useEffect, useState } from "react"
import axios from "axios"
import ReactMarkdown from 'react-markdown';
import user from '../assets/media/user.svg'
import calendar from '../assets/media/calendar.svg'
import clock from '../assets/media/clock.svg'
import { Helmet } from "react-helmet-async"
import ad_phone from '../assets/media/ad-phone.svg'
import Loader from "../components/loader"
import ReactGA from 'react-ga4'
import { baseAPI } from "../utils/urls"

const ArticlePage = () => {
    const { slug } = useParams()
    const [theArticle, setTheArtcile] = useState<any>('')
    const [displayLoader, setDisplayLoader] = useState('hidden')

    ReactGA.send({
        hitType: 'pageview',
        page:`/articles/${theArticle && theArticle.slug}`,
        title:`${theArticle && theArticle.title}`,
    })


    const schemaData = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": `${theArticle && theArticle.title}`,
        "image": `${theArticle && `https://seyeogunnowo.com/static/core/media/${theArticle.image}`}`,  
        "author": {
          "@type": "Person",
          "name": "Seye Ogunnowo",
          "url": "https://seyeogunnowo.com"
        },  
        "publisher": {
          "@type": "Organization",
          "name": "DeviceIcons",
          "logo": {
            "@type": "ImageObject",
            "url": "https://deviceicons.com/static/media/deviceicons-logo.98b187a82cc2f208b207.png"
          }
        },
        "datePublished": `${theArticle && theArticle.date_posted}`,
        "description": `${theArticle && theArticle.description}`
      };

    useEffect(()=>{
     getPosts()
    }, [])

    const getPosts = async () => {
        setDisplayLoader('block')
        // @ts-ignore
        const response = await axios.get(baseAPI+'api/posts/', {
            params: {
                slug: slug
            },
            headers: {
                'X-API-KEY': 'apiextseyekey'
            }
        })
        if (response.data[0].category === 'DeviceIcons'){
            setTheArtcile(response.data[0])
        }
        setDisplayLoader('hidden')
    }


    
    return (
        <>
        <Helmet>
            <title>{theArticle && theArticle.title} | DeviceIcons</title>
            <meta property="og:title" content={theArticle && theArticle.title}/>
            <meta property="og:image" content={theArticle && `https://seyeogunnowo.com/static/core/media/${theArticle.image}`}/>
            <meta name="twitter:title" content={theArticle && theArticle.title}/>
            <meta name="twitter:image" content={theArticle && `https://seyeogunnowo.com/static/core/media/${theArticle.image}`}/>
            <meta name="description" content={theArticle && theArticle.description}/>
            <meta property="og:description" content={theArticle && theArticle.description}/>
            <meta name="twitter:description" content={theArticle && theArticle.description}/>
            <link rel="canonical" href={theArticle && `https://deviceicons.com/articles/${theArticle.slug}`} />
        </Helmet>
        <Header />
        <div className="main-body">
            <div className="post-area">
                <div className="post-read">
                <div className="article-ad bg-purple-700 p-4 flex rounded-xl">
                    <div className="w-3/5 my-auto">
                        <img src={ad_phone} className="w-14" alt="" />
                    </div>
                    <div className="">
                    <p className="leading-5 mb-3">All the device icons you would ever need!</p>
                    <a href="/icons" className="p-2 text-white border-white border-2 text-sm rounded-lg">Try DeviceIcons</a>
                    </div>
                </div>
                <br />
                <div className="text-center">
                <p className="text-3xl">{theArticle.title}</p>
                </div>
                <br />
                <div className="flex flex-wrap gap-2 justify-center text-sm">
                <img src={user} className="btn-icon post-icon" alt="" /><span> Seye Ogunnowo</span>
                <img src={calendar} className="btn-icon post-icon" alt="" />    <span>{theArticle && (theArticle.date_posted).split('T')[0]}</span>
                <img src={clock} className="btn-icon post-icon" alt="" />    <span>{theArticle.read_time} minute Read</span>
                </div>
                <br />
                <div className="flex gap-1 flex-wrap justify-center">
                    {
                        theArticle && (theArticle.tags).map((tag:string)=>
                        <div className="">
                            <span className="bg-gray-200 rounded-full py-1 px-3 text-sm">{tag}</span>
                        </div>
                        )
                    }
                </div>
                <br />
                <img src={`	https://seyeogunnowo.com/static/core/media/${theArticle.image}`} className="w-full rounded-xl" alt="" />
                <br />
                <div className="post-read-content">
                    <ReactMarkdown>
                    {theArticle.content}
                    </ReactMarkdown>
                    <br />
                    <h3>FAQs about {theArticle.faq_title}</h3>
                    <ReactMarkdown>
                    {theArticle.faq_content}
                    </ReactMarkdown>
                </div>
                </div>
            </div>
            <Loader message={'Loading article'} display={displayLoader}/>
            </div>        
        <CookiesNotice />
        <Footer />
        </>
    )
}

export default ArticlePage