import loadGif from '../assets/media/loader.gif'
// @ts-ignore
const Loader = ({ message, display }) => {
    return(
        <>
        <div className={`loader ${display}`}>
            <img src={loadGif} alt="" />
        <p className='text-sm text-gray-700 text-center'>{message}</p>
        </div>
        </>
    )
}
export default Loader