import { useCookies } from 'react-cookie'
import logo from '../assets/media/deviceicons-logo.png'
import menu_bars from '../assets/media/menu-bars.svg'
import close from '../assets/media/close.svg'
import { useState } from 'react'
import { doNothing } from '../utils/utilFunctions'

const Header: React.FC = () => {
    const [token, setToken, removeToken] = useCookies(['user_token'])
    const [mobileMenuDisplay, setMobileMenuDisplay] = useState('hide')
    const [mobileMenuIcon, setMobileMenuIcon] = useState<any>(menu_bars)

    const showMobileMenu = ()=>{
        if(mobileMenuDisplay === 'hide') {
            setMobileMenuIcon(close)
            setMobileMenuDisplay('show')
        } else {
            setMobileMenuDisplay('hide')}
            setMobileMenuIcon(menu_bars)
        }
            


    return(
        <>
        <div className="main-menu">
            <img src={logo} alt="" className='main-logo'/>
            <div className='menu-items'>
                <a href="/" className='menu-item'>Home</a>
                <a href="/icons" className='menu-item'>Icons</a>
                <a href="/docs" className='menu-item'>Docs</a>
                <a href="/pricing" className='menu-item'>Pricing</a>
                <a href="/articles" className='menu-item'>Articles</a>
                <a href="/support" className='menu-item'>Support</a>
                {
                    token.user_token ? 
                    <div className='dropdown'>
                        <a className='menu-item dropdown-btn cursor-pointer'>Account</a>
                        <div className='dropdown-menu'>
                            <a href='/account-settings'>Account Settings</a>
                            <br />
                            <a href="" onClick={()=>{removeToken('user_token')}}>Logout</a>
                        </div> 
                    </div>
                    : 
                    <a href="/login" className='menu-item'>Login</a>
                }
            </div>
            <img src={mobileMenuIcon} alt="" className='menu-bars' onClick={showMobileMenu}/>
        </div>
        <div className={`mobile-menu-items ${mobileMenuDisplay}`}>
                <a href="/" className='menu-item'>Home</a>
                <br />
                <a href="/icons" className='menu-item'>Icons</a>
                <br />
                <a href="/docs" className='menu-item'>Docs</a>
                <br />
                <a href="/pricing" className='menu-item'>Pricing</a>
                <br />
                <a href="/articles" className='menu-item'>Articles</a>
                <br />
                <a href="/support" className='menu-item'>Support</a>
                <br />
                <br />
                <hr className='bg-gray-400'/>
                {
                    token.user_token ? 
                    <div className=''>
                            <a className='menu-item' href='/account-settings'>Account Settings</a>
                            <br />
                            <a href="" className='menu-item' onClick={()=>{removeToken('user_token')}}>Logout</a>
                    </div> 
                    : 
                    <a href="/login" className='menu-item'>Login</a>
                }
        </div>
        </>
    )
}

export default Header