import axios from "axios";
import { baseAPI } from "./urls";
import { useCookies } from "react-cookie";
import ReactGA from 'react-ga4'

export const doNothing = () => {
    return null
}

export const VerifyUser = async (email:string) => {

    try {
      const response = await axios.post(baseAPI+'verify-customer/', <any>{
        email: email,
      });

      return response.data

    } catch (error) {
      console.log("Not signed is.")
    }
  };

  export const sendGAEvent = (category: string, action: string, label: string) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
  })
  }