import { useEffect, useState } from "react"
import CookiesNotice from "../components/cookiesNotice"
import Footer from "../layout/footer"
import Header from "../layout/header"
import axios from "axios"
import user from '../assets/media/user.svg'
import calendar from '../assets/media/calendar.svg'
import clock from '../assets/media/clock.svg'
import { Helmet } from "react-helmet-async"
import Loader from "../components/loader"
import ReactGA from 'react-ga4'
import { baseAPI } from "../utils/urls"


const Articles = () => {
    ReactGA.send({
        hitType: 'pageview',
        page:"/articles",
        title:"Articles",
    })

    const [articles, setArtciles] = useState<any>([])
    const [displayLoader, setDisplayLoader] = useState('hidden')

    useEffect(()=>{
     getPosts()
    }, [])

    const getPosts = async () => {
        setDisplayLoader('block')
        const response = await axios.get(baseAPI+'api/posts/', {
            params: {
                category:'DeviceIcons' 
            },
            headers: {
                'X-API-KEY': 'apiextseyekey'
            }
        })
        //@ts-ignore
        const sortedData = response.data.sort((a,b)=> new Date(b.date_posted) - new Date(a.date_posted));
        setArtciles(sortedData)
        setDisplayLoader('hidden')
    }
    
    return (
        <>
        <Helmet>
            <title>Articles | DeviceIcons</title>
            <meta property="og:title" content="Articles | DeviceIcons"/>
            <meta name="twitter:title" content="Articles | DeviceIcons"/>
            <meta name="description" content="Read our articles, and learn how to enhance your websites' and apps' visual appeal."/>
            <meta property="og:description" content="Read our articles, and learn how to enhance your websites' and apps' visual appeal."/>
            <meta name="twitter:description" content="Read our articles, and learn how to enhance your websites' and apps' visual appeal."/>
        </Helmet>
        <Header />
        <div className="main-body">
        <div className="title-area">
            <div className="page-title">
                Articles
            </div>
        </div>
        <div className="posts-body">
            {
                articles && articles.map((post: any)=>
                <>
                <div className="post">
                    <h3 className="text-2xl">{post.title}</h3>
                    <br />
                    <div className="flex flex-wrap gap-2 text-sm">
                    <img src={user} className="btn-icon post-icon" alt="" /><span> Seye Ogunnowo</span>
                    <img src={calendar} className="btn-icon post-icon" alt="" />    <span>{post && (post.date_posted).split('T')[0]}</span>
                    <img src={clock} className="btn-icon post-icon" alt="" />    <span>{post.read_time} minute Read</span>
                    </div>
                    <br />
                <div className="flex gap-1 flex-wrap">
                    {
                        post && (post.tags).map((tag:string)=>
                        <div className="">
                            <span className="bg-gray-200 rounded-full py-1 px-3 text-sm">{tag}</span>
                        </div>
                        )
                    }
                </div>
                    <br />
                    <hr />
                    <br />
                    <div className="post-content-area">
                        <div className="post-excerpt p-2">
                            <p>{post.excerpt}</p>
                        </div>
                        <div  className="post-image">
                            <img src={`	https://seyeogunnowo.com/static/core/media/${post.image}`} alt="" className="post-image" />
                        </div>

                    </div>
                    <a className="px-5 py-2 rounded-full bg-purple-700 text-lg text-white"  href={`articles/${post.slug}`}>Read More</a>
                </div>
                </>
                )
            }
        </div>
        <Loader message={'Loading articles'} display={displayLoader} />
        </div>        
        <CookiesNotice />
        <Footer />
        </>
    )
}

export default Articles