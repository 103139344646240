import { KeyboardEventHandler, useEffect, useRef, useState } from "react"
import Header from "../layout/header"
import { icons, healthcareIcons } from "../utils/importIcons"
import axios from "axios"
import Footer from "../layout/footer"
import close from '../assets/media/close.svg'
import { shareImage } from "../utils/images"
import download from '../assets/media/download.svg'
import { doNothing, sendGAEvent, VerifyUser } from "../utils/utilFunctions"
import { useCookies } from "react-cookie"
import { Helmet } from "react-helmet-async"
import ReactGA from 'react-ga4'
import CookiesNotice from "../components/cookiesNotice"
import bp_mon from "../assets/media/bp-mon.svg"
import { baseAPI } from "../utils/urls"

const HealthcareIconsPage: React.FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page:"/packs/healthcare",
        title:"Healthcare Pack",
    })

    const [userDetails, setUserDetails] = useCookies(['email'])

    VerifyUser(userDetails.email)

    interface Icon {
        icon: any;
        iconInner: any;
        name: string;
        width: any;
        downloadURL: string;
        plan: string;
    }
    
    const [selectedIcon, setSelectedIcon] = useState('')
    const [searchTerm, setSearchTerm] =  useState('')
    const [renderedIcon, setRenderedIcon] = useState<Icon>({
        icon: '',
        iconInner: '',
        name: '',
        width: '',
        downloadURL: '',
        plan: 'premium'
    })

    const pickedIcon = useRef<string>('')
    const downloadURL = useRef<string>('') 

    const [display, setDisplay] = useState<string>('hide')
    const [userStatus, setUserStatus] = useState<any>("")

    const getUserStatus = async () => {
        const response = await axios.post(baseAPI+'verify-customer/', {
            email: userDetails.email,
          });
        setUserStatus(response.data)
    }

    useEffect(()=>{
        getUserStatus()
    }, [])

    const selectIcon = async (e: string) => {
        pickedIcon.current = e
            setSelectedIcon(e)
            const response = await axios.get(pickedIcon.current);
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(response.data, 'image/svg+xml');
            const svgElement = svgDoc.documentElement;
            svgElement.setAttribute('viewBox', `0 0 ${svgElement.getAttribute('width')} ${svgElement.getAttribute('height')}`);
            if (pickedIcon.current.includes('context')){
            svgElement.setAttribute('width', '240');
            svgElement.setAttribute('height', 'auto');
            } else if (pickedIcon.current.includes('desktop') || pickedIcon.current.includes('laptop')){
            svgElement.setAttribute('width', '250');
            svgElement.setAttribute('height', 'auto');
            } else if (pickedIcon.current.includes('phone')){
            svgElement.setAttribute('width', '170');
            svgElement.setAttribute('height', 'auto');
            } else if (pickedIcon.current.includes('tablet')){
            svgElement.setAttribute('width', '220');
            svgElement.setAttribute('height', 'auto');
            } else if (pickedIcon.current.includes('watch')){
                svgElement.setAttribute('width', '100');
                svgElement.setAttribute('height', 'auto');
            } else {
                svgElement.setAttribute('width', '180');
                svgElement.setAttribute('height', 'auto');
            }
            
            
            const attribution = document.createComment("© 2024 DeviceIcons. Free for personal and commercial use. Attribution required. License: https://www.deviceicons.com/licence");
            svgElement.append(attribution);
            const svgName = (pickedIcon.current.split('/').pop()!).replace('.svg', '')
            setRenderedIcon((iconData)=>({
                ...iconData,
                icon: svgElement.outerHTML,
                iconInner: svgElement,
                name: svgName.replace(/\..*$/, ''),
                width: svgElement.getAttribute('width')
            }))

            

        setDisplay('show')
    }

    const hideModal = (): void => {
        setDisplay('hide')
    }


    const resizeIcon = (size: string): void => {
        const newResize = renderedIcon.iconInner
        newResize.setAttribute('width', size)
        setRenderedIcon((iconData)=>({
            ...iconData,
            icon: newResize.outerHTML,
            iconInner: newResize
        }))
        
    }

    const setStrokeWidth = (width: string): void => {
        const newStroke = renderedIcon.iconInner
        newStroke.querySelectorAll('path').forEach((element: HTMLElement)=>{
            element.setAttribute('stroke-width', width)
        })
        setRenderedIcon((iconData)=>({
            ...iconData,
            icon: newStroke.outerHTML,
            iconInner: newStroke
        }))
    }

    const downloadIcon = () => {
        sendGAEvent('Button Click', `icon_download_${renderedIcon.name}`, `icon_download_${renderedIcon.name}`)
        if (renderedIcon.icon){
            const blob = new Blob([renderedIcon.icon], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${renderedIcon.name}.svg`;
            a.click()
        }
    }

    const startSearch: any = (e: any) => {
        document.querySelectorAll('.icon-group').forEach((icon)=>{
           !icon.id.toLowerCase().includes(e.target.value.toLowerCase()) || !icon.id.toUpperCase().includes(e.target.value.toUpperCase()) ? icon.classList.add('hidden') : icon.classList.remove('hidden')
        })
    }

    return(
        <>
        <Helmet>
        <title>Healthcare Icons | DeviceIcons</title>
        <meta name="description" content="Browse quality icons of phones, laptops, desktops, tablets and many more devices for any project."/>
        <meta name="keywords" content="device icons, phone icons, tablet icons, desktop icons, laptop icons, icon pack, free icons, premium icons, SVG icons, customisable icons, web development, app development"/>
        <meta name="author" content="DeviceIcons"/>
        <meta property="og:title" content="Icons | DeviceIcons"/>
        <meta property="og:description" content="Browse quality icons of phones, laptops, desktops, tablets and many more devices for any project."/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://deviceicons.com/icons"/>
        <meta property="og:image" content={shareImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Icons | DeviceIcons"/>
        <meta name="twitter:description" content="Browse quality icons of phones, laptops, desktops, tablets and many more devices for any project."/>
        <meta name="twitter:image" content={shareImage}/>
        <script type="application/ld+json">
        </script>
        </Helmet>
        <Header />
        <div className="main-body">
        <div className="pt-32 pb-14 text-white text-center" style={{background: "#01944b"}}>
            <img src={bp_mon} className="pack-icon" alt="" />
            <h2 className="text-4xl text-white"><b>Healthcare Device Icons Pack</b></h2>
            <br />
            <p className="text-xl">Browse, select and modify an icon's size and thickness before downloading</p>
            <br />
            <input type="text" className="search-bar" placeholder="Start Searching" onKeyUp={(e)=>startSearch(e)}/>
            <br />
            <br />
            <div className="mx-auto w-fit px-5">
            <p className="border py-1 px-2 rounded-full text-sm w-fit text-center">Check out other <b className="underline"><a href="/industry-packs">Industry Packs</a></b></p>
            </div>
        </div>
        <div className="icons-area">
        {healthcareIcons.map((icon, index) => 
            <div className="icon-group" id={(icon.split('/').pop()!).replace('.svg', '')}>
            <div key={index} className="icon" onClick={()=>{selectIcon(icon)}}>
            <img src={icon} alt={`${(((icon.split('/').pop()!).replace('.svg', '')).replace(/\..*$/, '')).replace(/-/g, ' ')} icon`} className="icon-set" style={{width: icon.includes('context') ? '80%' : icon.includes('desktop') || icon.includes('laptop') ?'75%' : icon.includes('tablet') ? '60%' : icon.includes('phone') ? '42%' : icon.includes('smart-watch') ? '32%': '60%'}} />
            <div className="premium-tag">Premium</div>
            </div>
            <span style={{fontSize: '13px'}} className="text-center">{((icon.split('/').pop()!).replace('.svg', '')).replace(/\..*$/, '')}</span>
            </div>      
       
        )}
        </div>
       <div className={`modal-backdrop ${display}`}>
       <div className={`icon-modal shadow-xl`}>
        <div className="w-full sticky top-0 flex gap-5 bg-white rounded-lg p-4">
        <h3 className="text-xl m-2">{renderedIcon.name}</h3> 
        <img src={close} alt="" onClick={hideModal} className="btn-icon absolute right-0 m-5 cursor-pointer"/>
        </div>
            
            <br />
            {
               renderedIcon.plan === "free" || userStatus.user_plan === renderedIcon.plan 
               ?
               <div className="flex icon-modal-inner gap-1">
               <div className="basis-2/5">
               <div dangerouslySetInnerHTML={{
           __html: renderedIcon.icon || `<img src="${selectIcon}" alt="icon" class="icon" />`,
         }}></div>
               </div>
               <div className="basis=3/5">
                   <div className="border-2 rounded-lg p-3">
                       <h3>Scale</h3>
                       <form className="m-4 border-gray-200 rounded-lg">
                       <input type="radio" name="iconSize" id="small" onChange={()=>{resizeIcon(`${renderedIcon.width/4}`)}}/>
                       <label htmlFor="small" className="radio">
                        Small</label>
                        <input type="radio" name="iconSize" id="medium" onChange={()=>{resizeIcon(`${renderedIcon.width/2}`)}}/>    
                       <label htmlFor="medium" className="radio">
                       Medium</label>
                       <input type="radio" name="iconSize" id="large" onChange={()=>{resizeIcon(`${renderedIcon.width}`)}} defaultChecked/>
                       <label htmlFor="Large" className="radio">
                       Large</label>
                       </form>
                   </div>
                   <br />
                   <div className="border-2 rounded-lg p-3">
                       <h3>Stroke Width</h3>
                       <form className="m-4 border-1 border-gray-200 rounded-lg">
                           <input type="radio" name="iconSize" id="light" onChange={()=>{setStrokeWidth('5')}}/>
                           <label htmlFor="light" className="radio">
                                Light</label>
   
                           <input type="radio" name="iconSize" id="normal" onChange={()=>{setStrokeWidth('10.3')}} defaultChecked/>    
                           <label htmlFor="normal" className="radio">
                                Medium</label>
   
                           <input type="radio" name="iconSize" id="heavy" onChange={()=>{setStrokeWidth('20')}}/>
                           <label htmlFor="heavy" className="radio">
                                Heavy</label>
   
                       </form>
                   </div>
                   <br />
                   <div className="icon-buttons">
                   <button className="py-2 px-6  rounded-full bg-purple-600 text-white flex" onClick={downloadIcon}>Download SVG <img src={download} className="btn-icon" alt="" /></button>
                   <a className="py-2 px-6  rounded-full bg-gray-700 text-white flex" href={`/icons/${renderedIcon.name}`}>Icon Page </a>
                   </div>
               <br />
               <br />
               </div>
               </div>
               : userStatus.user_plan != renderedIcon.plan ?
               <div className="px-6 py-8 mx-4 my-3 border rounded-lg">
                   <h3 className="text-2xl">Get the <b className="text-purple-800">{renderedIcon.name}</b> icon with a Premium Plan!</h3>
                   <br />
                   <p className="lg">Subscribe and get access to:</p>
                   <p>- <b>All Premium</b> icons</p>
                   <p>- More icon customisation including <b>colours and extra sizes</b>.</p>
                   <p>- <b>Higher priority support</b></p>
                   <br />
                   <br />
                   <a className="px-5 py-2 bg-purple-700 text-white rounded-lg" href="/pricing">Check out Plans</a>
               </div>
               :
               doNothing()  
            }
        </div>
        </div>
        </div>
        <CookiesNotice /> 
        <Footer/>
        </>
    )
}

export default HealthcareIconsPage