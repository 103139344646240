import { upload } from "@testing-library/user-event/dist/upload"
import Footer from "../layout/footer"
import Header from "../layout/header"
import { useState } from "react"

const Edit = () => {

    const [uploadedSVG, setUploadedSVG] =  useState<any>()
    const [unsanitisedSVG, setUnsanitisedSVG] = useState<any>()

    const parseSVG = (svgString: string) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
        return svgDoc.documentElement
    }


    
    const sanitizeSVG = (svgString: string, height: string, width: string) => {
        // Use a DOMParser to modify the SVG attributes
       const svgElement = parseSVG(svgString)
    
        // Fix `height` and `width` attributes if set to "auto"
        if (svgElement.getAttribute("height") === "auto") {
          svgElement.setAttribute("height", height);
        }
        if (svgElement.getAttribute("width") === "auto") {
          svgElement.setAttribute("width", width);
        }
        // Serialize the modified SVG back to a string
        const serializer = new XMLSerializer();
        return serializer.serializeToString(svgElement);
      };

    const updateSize = (height: string, width: string) => {
        const parsedSVG = parseSVG(uploadedSVG)
        parsedSVG.setAttribute('viewBox', `0 0 400 400`)
        setUploadedSVG(sanitizeSVG(`${parsedSVG}`, height, width))
    }

    const updateColour = (colour:string) => {
        
    }


    const uploadSVG = (e:any) => {
        const file = e.target.files[0];

        // Ensure a file is selected and it is an SVG file
        if (file && file.type === "image/svg+xml") {
          const reader = new FileReader();
      
          // Handle file reading completion
          reader.onload = function(e) {
            //@ts-ignore
            setUnsanitisedSVG(e.target.result);
            //@ts-ignore
            const sanitizedSVG = sanitizeSVG(e.target.result, "100%", "100%");
      
            // Render the SVG content in the container
             setUploadedSVG(sanitizedSVG)
          };
      
          // Read the file content as text
          reader.readAsText(file);
        } else {
          alert('Please upload a valid SVG file.');
        }
    }
    return(
        <>
        <Header />
        <div className="main-body">
            <div className="page-title">
                SVG Editing Tool
            </div>
             <br />
            <div className="edit-body">
                <div className="bg-white p-6 rounded-xl shadow-lg ed-1 border-2">
                    <h3 className="text-2xl">SVG</h3>
                    <p className="">(Actual size of uploaded SVG)</p>
                    <div className="w-full h-full">
                        <div className="flex justify-center my-auto h-fit">
                        <div className="" dangerouslySetInnerHTML={{ __html: uploadedSVG }}></div>
                        </div>
                    </div>
                </div>
                <div className="ed-2">
                <div className="basis=3/5">
                <input type="file" className="py-2 px-6 rounded-full bg-gray-800 text-white flex" onChange={(e)=>{uploadSVG(e)}} />
                <br />
                    <div className="border-2 rounded-lg p-3">
                        <h3>Scale</h3>
                        <form className="m-4 border-gray-200 rounded-lg">
                        <input type="radio" name="iconSize" id="small" onChange={()=>{updateSize("80", "80")}}/>
                        <label htmlFor="small" className="radio">
                         Small x2</label>
                        <input type="radio" name="iconSize" id="medium" onChange={()=>{updateSize("120px", "120px")}}/>    
                        <label htmlFor="medium" className="radio">
                        Small</label>
                        <input type="radio" name="iconSize" id="large" onChange={()=>{updateSize("170px", "170px")}} defaultChecked/>
                        <label htmlFor="Large" className="radio">
                        Normal Size</label>
                        <input type="radio" name="iconSize" id="larger" onChange={()=>{updateSize("170%", "170%")}}/>
                        <label htmlFor="Larger" className="radio">
                        Larger</label>
                        </form>

                    </div>
                    <br />
                    <div className="border-2 rounded-lg p-3">
                        <h3>Stroke Width</h3>
                        <form className="m-4 border-1 border-gray-200 rounded-lg">
                            <input type="radio" name="iconSize" id="light" />
                            <label htmlFor="light" className="radio">
                                 Light</label>
    
                            <input type="radio" name="iconSize" id="normal" defaultChecked/>    
                            <label htmlFor="normal" className="radio">
                                 Medium</label>
                            <input type="radio" name="iconSize" id="heavy" />
                            <label htmlFor="heavy" className="radio">
                                 Heavy</label>
    
                        </form>
                    </div>
                    <br />
                    <div>
                        <input type="color" />
                    </div>
                    <div className="icon-buttons">
                    <button className="py-2 px-6  rounded-full bg-purple-600 text-white flex">Download SVG<img className="btn-icon" alt="" /></button>
                    </div>
                </div>
            </div>

        </div>
        </div>
        <Footer />
        </>
    )
}

export default Edit