import Header from "../layout/header"
import about_pic from "../assets/media/seye_deviceicons.jpg"
import { Helmet } from "react-helmet-async"

const SeyeOgunnowo = () => {

    const schemaData = {
      "@context": "https://schema.org/",
      "@type": "Person",
      "name": "Seye Ogunnowo",
      "email": "mailto:seye.ogunnowo@gmail.com",
      "url": "https://seyeogunnowo.com",
      "image": "/static/core/media/Seye%20Official%20Photo.jpg",
      "sameAs": [
        "https://linkedin.com/in/seyeogunnowo",
        "https://twitter.com/seyeogunnowo",
        "https://instagram.com/seyeogunnowo",
        "https://youtube.com/@seyeogunnowo",
        "https://github.com/seyeogunnowo",
        "https://seyeogunnowo.com"
      ]
    }
    

    return(
        <>
        <Helmet>          
            <title>Seye Ogunnowo | DeviceIcons</title>
            <meta property="og:title" content="Seye Ogunnowo | DeviceIcons"/>
            <meta property="og:image" content={about_pic}/>
            <meta name="twitter:title" content="Seye Ogunnowo | DeviceIcons"/>
            <meta name="twitter:image" content={about_pic}/>
            <meta name="description" content="Seye Ogunnowo is a Nigerian software engineer. He is known for building web applications and cloud-based software using a combination of technologies, including Python and JavaScript."/>
            <meta property="og:description" content="Seye Ogunnowo is a Nigerian software engineer. He is known for building web applications and cloud-based software using a combination of technologies, including Python and JavaScript."/>
            <meta name="twitter:description" content="Seye Ogunnowo is a Nigerian software engineer. He is known for building web applications and cloud-based software using a combination of technologies, including Python and JavaScript."/>
            <script type="application/ld+json">
            {JSON.stringify(schemaData)}
            </script>
        </Helmet>
        <Header/>
        <div className="main-body">
            <div className="page-title">
                <h1>Seye Ogunnowo</h1>
            </div>
            <div className="white-body">
                <div className="about">
                    <div className="about-pic">
                        <img src={about_pic} className="rounded-xl" alt="" />
                    </div>
                    <div className="about-body">
                        <p className="text-xl">Seye Ogunnowo is a Nigerian software engineer. He is known for building web applications and cloud-based software using a combination of technologies, including Python and JavaScript.</p>
                        <br />
                        <p className="text-xl">Seye created DeviceIcons and is the current CEO.</p>
                        <br />
                        <a className="bg-purple-600 text-white py-3 px-4 rounded-xl" href="https://seyeogunnowo.com" target="_blank">View Website</a>
                        <br />
                        <br />
                        <hr />
                        <br />
                        <div className="text-lg flex flex-wrap gap-5 font-bold text-purple-800">
                            <a href="https://facebook.com/seyeogunnowo">Facebook</a>
                            <a href="https://twitter.com/seyeogunnowo">X</a>
                            <a href="https://linkedin.com/in/seyeogunnowo">LinkedIn</a>
                            <a href="https://instagram.com/seyeogunnowo">Instagram</a>
                            <a href="https://github.com/seyeogunnowo">GitHub</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SeyeOgunnowo