import CookiesNotice from "../components/cookiesNotice"
import Footer from "../layout/footer"
import Header from "../layout/header"
import chip from "../assets/media/chip.svg"
import bp_mon from "../assets/media/bp-mon.svg"
import { Helmet } from "react-helmet-async"
import { sendGAEvent } from "../utils/utilFunctions"
import ReactGA from 'react-ga4'


const IndustryPacks = () => {
    ReactGA.send({
        hitType: 'pageview',
        page:"/industry-packs",
        title:"Industry Packs",
    })

    return(
        <>
        <Helmet>
        <title>Indutsry Packs | DeviceIcons</title>
        <meta name="description" content="From Tech to Healthcare, Education to Agriculture and much more. Industry device icons packed just for you."/>
        <meta name="keywords" content="industry icons, icon pack, free icons, premium icons, SVG icons, customisable icons, web development, app development"/>
        <meta name="author" content="DeviceIcons"/>
        <meta property="og:title" content="Industry Packs | DeviceIcons"/>
        <meta property="og:description" content="From Tech to Healthcare, Education to Agriculture and much more. Industry device icons packed for you."/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://deviceicons.com/icons"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Industry Packs | DeviceIcons"/>
        <meta name="twitter:description" content="From Tech to Healthcare, Education to Agriculture and much more. Industry device icons packed for you."/>
        <script type="application/ld+json">
        </script>
        </Helmet>
        <Header />
        <div className="main-body">
        <div className="title-area">
        <h2 className="page-title">Industry Packs</h2>
        </div>
        <div className="bg-white h-34 pt-12 pb-40">
        <div className="flex flex-wrap gap-8 justify-center m-4 text-white text-center">
            <div className="industry-pack" style={{background: "#004773"}}>
            <img src={chip} className="pack-icon" alt="" />
            <h5 className="text-2xl">Tech Device Icons</h5>
            <br />
            <p>Cloud, servers, software, hardware, components and more.</p>
            <br />
            <a className="py-2 px-3 border-2 border-white rounded-lg w-4/5" href="/packs/technology">Explore Pack</a>
            </div>
            
            <div className="industry-pack" style={{background: "#01944b"}}>
            <img src={bp_mon} className="pack-icon" alt="" />
            <h5 className="text-2xl">Healthcare Icons</h5>
            <br />
            <p>Monitors, test kits, instruments, scanners and more.</p>
            <br />
            <a className="py-2 px-3 border-2 border-white rounded-lg w-4/5" href="/packs/healthcare">Explore Pack</a>
            </div>

            <div className="industry-pack" style={{background: "#ededed"}}>
            <h5 className="text-2xl text-black">More Packs <br /> Coming Soon</h5>
            </div>

        </div>
        
        </div>
        </div>
        <CookiesNotice />
        <Footer />
        </>
    )
}

export default IndustryPacks