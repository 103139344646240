import { useEffect, useState } from "react"
import Footer from "../layout/footer"
import Header from "../layout/header"
import loginBG from '../assets/media/login-bg.jpg'
import axios from "axios"
import { useCookies } from "react-cookie"
import { baseAPI } from "../utils/urls"
import { Helmet } from "react-helmet-async"
import { shareImage } from "../utils/images"
import ReactGA from 'react-ga4'
import CookiesNotice from "../components/cookiesNotice"
import { doNothing, sendGAEvent } from "../utils/utilFunctions"



const Login: React.FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page:"/login",
        title:"Login",
    })

    const [action, setAction] = useState('login')
    const [name, setName] = useState('');
    const [password, setPassword] = useState<string|null>(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState<null|string>(null);
    const [token, setToken] = useCookies<string>(['user_token'])
    const [userDetails, setUserDetails] = useCookies(['email'])


    useEffect(()=>{
        if (token.user_token){
            window.location.href='/'
        } 
    })

    const loginUser = async (e: any) => {
        e.preventDefault()
        sendGAEvent('Button Click', 'login_attempt', 'login_attempt')
        try{
          const response = await axios.post(baseAPI+'api/token/', {
            username: email,
            password: password
        })
        setToken('user_token', response.data.access)
        setUserDetails('email', email)
    }
    catch (error: any) {
        setError('There was a problem logging you in.')
    }
}

    const validatePassword = () => {
        if (password != confirmPassword) {
            setError('Password does not match')
        } else {
            setError('')
        };
    }

    const registerUser = async (e: any) => {
        e.preventDefault();
        sendGAEvent('Button Click', 'signup-attempt', 'signup-attempt')
       if(!error){
        try {
            const response = await axios.post(baseAPI+'register/', {
                name,
                password,
                email
            });
            setToken('user_token', response.data.access); // Handle the response (e.g., store the JWT)
            setUserDetails('email', email)
        } catch (error: any) {
            setError('Something went wrong signing you up.');
        }
       }
    }

    return(
        <>
        <Helmet>
        <title>Login | DeviceIcons</title>
        <meta name="description" content="Login to access premium icons and more at DeviceIcons."/>
        <meta name="author" content="DeviceIcons"/>
        <meta property="og:title" content="Login | DeviceIcons"/>
        <meta property="og:description" content="Login to access premium icons and more at DeviceIcons."/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://deviceicons.com/login"/>
        <meta property="og:image" content={shareImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Login | DeviceIcons"/>
        <meta name="twitter:description" content="Login to access premium icons and more at DeviceIcons."/>
        <meta name="twitter:image" content={shareImage}/>
        </Helmet>
            <Header />
            <div style={{backgroundImage: `url(${loginBG})`, backgroundSize: 'cover', height: '100%'}} className="pt-36 main-body  bg-fixed shadow-xl">
                <div className="login-box">
                    {
                        action === 'login' ? 
                        <form>
                            <h3 className="text-center text-2xl">Login</h3>
                            <br />
                            {
                            error ? <div><p className="text-center text-red-600">{error}</p><br /></div> : null
                            }
                            <label htmlFor="">
                                Email
                                <input type="email" className="input" onChange={(e)=>{setEmail(e.target.value)}} required/>
                            </label>
                            <br />
                            <br />
                            <label htmlFor="">
                                Password
                                <input type="password" className="input" onChange={(e)=>{setPassword(e.target.value)}} required/>
                            </label>
                            <br />
                            <br />
                            <button className="login-btn" onClick={(e)=>{loginUser(e)}}>Log In</button>
                            <br />
                            <br />
                            <p className="text-center">Don't have an account? <b className="cursor-pointer" onClick={()=>{setError(''); setAction('sign up')}}>Sign Up</b></p>
                        </form>
                        : <form>
                        <h3 className="text-center text-2xl">Sign Up</h3>
                        <br />
                        {
                         error ? <div><p className="text-center text-red-600">{error}</p><br /></div> : null
                        }
                        <label htmlFor="">
                            Name
                            <input type="text" className="input" onChange={(e)=>{setName(e.target.value)}} required/>
                        </label>
                        <br />
                        <br />
                        <label htmlFor="">
                            Email
                            <input type="email" className="input" onChange={(e)=>{setEmail(e.target.value)}} required/>
                        </label>
                        <br />
                        <br />
                        <label htmlFor="">
                            Password
                            <input type="password" className="input" onChange={(e)=>{setPassword(e.target.value)}} onKeyUp={()=>{confirmPassword != '' ? validatePassword(): doNothing()}} required/>
                        </label>
                        <br />
                        <br />
                        <label htmlFor="">
                           Confirm Password
                            <input type="password" className="input" onChange={(e)=>{setConfirmPassword(e.target.value)}} onKeyUp={validatePassword}/>
                        </label>
                        <br />
                        <br />
                        <button className="login-btn" type="submit" onClick={(e)=>{registerUser(e)}}>Sign Up</button>
                        <br />
                        <br />
                        <p className="text-center">Already have an account? <b className="cursor-pointer" onClick={()=>{setError(''); setAction('login')}}>Login</b></p>
                    </form>
                    }
                    
                </div>
                </div>
                <CookiesNotice />
                <Footer />
        </>
    )
}

export default Login