import { Helmet } from "react-helmet-async"
import Footer from "../layout/footer"
import Header from "../layout/header"
import ReactGA from 'react-ga4'
import CookiesNotice from "../components/cookiesNotice"



const Licence: React.FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page:"/licence",
        title:"Licence",
    })
    
    return(
        <>
        <Helmet>
            <title>Licence | DeviceIcons</title>
        </Helmet>
        <Header/>
        <div className="main-body">
        <div className="title-area">
            <div className="page-title">
            Licence
            </div>
        </div>
            <div className="m-5 bg-white p-4 leading-7 rounded-lg">
           <h1 className="text-2xl font-bold"> DeviceIcons License Agreement</h1>
           <br />
This License Agreement outlines the terms and conditions for using our icons. By downloading or using our icons, you agree to comply with this License Agreement.
<br />
<br />
<h2 className="text-xl font-bold">1. Types of Licenses</h2>
<h3 className="text-lg font-bold">1.1 Free Icons License</h3>
<b>Usage:</b> <br />
Free icons can be used for personal and commercial projects without any cost.
Attribution is required and appreciated. <br />
<b>Permitted:</b> <br />

<p>Use in personal and commercial projects (websites, applications, presentations, etc.).
Modify icons to fit your project needs. <br /></p>
<b>Prohibited:</b> <br />

Reselling, redistributing, or sub-licensing the free icons.
Using icons in a logo or trademark without permission.
<br />
<br />
<b className="text-lg">1.2 Pro Icons License</b> <br />
<b>Usage:</b><br />

Pro icons are available for purchase and can be used for personal and commercial projects.
No attribution is required.
<br />
<b>Permitted:</b><br />

Use in personal and commercial projects (websites, applications, presentations, etc.).
Modify icons to fit your project needs <br />
<b>Prohibited: </b><br />

Reselling, redistributing, or sub-licensing the pro icons.
Using icons in a logo or trademark without permission.
<br />
<br />
<h1 className="text-xl font-bold">2. General Terms</h1>
<h2 className="text-lg font-bold">2.1 Ownership:</h2>
All icons remain the property of DeviceIcons. You are granted a license to use the icons, but ownership is not transferred.
<br />
<h2 className="text-lg font-bold">2.2 Updates:</h2>
DeviceIcons may update the icons or the license terms at any time. Updated icons or terms will be available on our website.

<h2 className="text-lg font-bold">2.3 Termination:</h2>
This license will terminate automatically if you fail to comply with any of its terms. Upon termination, you must stop using the icons and delete any copies in your possession.
<br />
<br />
<h1 className="text-xl font-bold">3. Disclaimer of Warranties</h1>
The icons are provided "as is" without warranty of any kind, either expressed or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. DeviceIcons does not warrant that the icons will meet your requirements or that their operation will be uninterrupted or error-free.
<br />
<br />
<h1 className="text-xl font-bold">4. Limitation of Liability</h1>
In no event shall DeviceIcons be liable for any damages (including, without limitation, lost profits, business interruption, or lost information) arising out of the use or inability to use the icons, even if DeviceIcons has been advised of the possibility of such damages.
<br />
<br />
<h1 className="text-xl font-bold">5. Applicable Laws</h1>
This License Agreement shall be governed by and construed in accordance with the laws of Nigeria, without regard to its conflict of law principles. Any disputes arising under or in connection with this agreement shall be subject to the exclusive jurisdiction of the courts of Nigeria.
<br />
<br />
<h1 className="text-xl font-bold">6. Contact Information</h1>
If you have any questions or need further clarification regarding this License Agreement, please contact us at:
<br />
Email: support@deviceicons.com <br />
Phone: +2348134222532 <br />
Thank you for using DeviceIcons!
            </div>
            </div>
        <CookiesNotice />
        <Footer/>
        </>

    )
}

export default Licence